body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f0f2f5; /* Light gray background */
}

.ant-card {
  border-radius: 8px; /* Rounded corners for cards */
}

.ant-btn-primary {
  background-color: #1890ff; /* Customize button color */
  border-color: #1890ff;
}

.ant-btn-primary:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}
